




























































import { defineComponent, computed, ref } from '@vue/composition-api';
import { downloadCsv } from '@/admin/util';
import myAttributes from '@/composition/myAttributes';
import report, { InfoItem, SubscriptionCountItem } from '@/composition/report';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';
import FcDateFilter from '@/components/FcDateFilter.vue';

export default defineComponent({
  name: 'ReportDownload',
  components: {
    FcRoleLoading,
    FcRoleDeny,
    FcDateFilter,
  },
  props: {
    prevPage: {
      type: String,
      required: false,
      default: '/report',
    },
  },
  setup() {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('report'));
    const isPlanService = process.env.VUE_APP_GROUP_TYPE === 'plan'; // plan or season

    // データ取得
    report.getReportData();

    const selectedCsvType = ref<string>('');
    const csvFilterDates = ref<(number | null)[]>([null, null]);
    const csvData = computed(() => {
      const date = `${report.dateString.replace(/\//g, '')}${report.time.replace(':', '')}`;
      // ユーザーヘッダー
      const usersHeaders = [
        { text: 'ユーザーID', value: 'username' },
        { text: '誕生日', value: 'birthdate' },
        { text: '性別', value: 'gender' },
        { text: '居住地', value: 'prefecture' },
        { text: '職業', value: 'occupation' },
        { text: '登録日', value: 'createDate' },
        { text: '退会日', value: 'deleteDate' },
        { text: '退会済', value: 'csv.isDeleted' },
        { text: '年齢', value: 'age' },
        { text: '年代', value: 'ageGroup' },
      ];
      if (isPlanService)
        usersHeaders.push(
          { text: '加入中のプラン', value: 'csv.subscriptionPlanNames' },
          { text: 'プラン決済失敗あり', value: 'csv.isFailedLatestSubscriptionPayment' },
          { text: '途中離脱あり', value: 'csv.isUncompletedSignup' }
        );
      else usersHeaders.push({ text: '加入中のシーズン', value: 'seasonIds' });

      // プラン別加入者数：subscriptionCounts
      const subscriptionsFilterdData = report.getFilteredData(report.subscriptionCounts, csvFilterDates.value);
      const subscriptionsFilteredItems = subscriptionsFilterdData
        ? (subscriptionsFilterdData.items as SubscriptionCountItem[])
        : [];
      const subscriptionsFilterdDates = subscriptionsFilterdData?.dates || '';
      const subscriptionsEntries = subscriptionsFilteredItems.flatMap((item) => item.plans);

      // プラン売上：planSales
      const planSalesFilterdData = report.getFilteredData(report.salesInfos.plans, csvFilterDates.value);
      const planSalesFilteredItems = planSalesFilterdData ? (planSalesFilterdData.items as InfoItem[]) : [];
      const planSalesFilterdDates = planSalesFilterdData?.dates || '';
      const planSalesEntries = planSalesFilteredItems.flatMap((item) => item.infos);

      // 個別課金売上：productSales
      const productSalesFilterdData = report.getFilteredData(report.salesInfos.products, csvFilterDates.value);
      const productSalesFilteredItems = productSalesFilterdData ? (productSalesFilterdData.items as InfoItem[]) : [];
      const productSalesFilterdDates = productSalesFilterdData?.dates || '';
      const productSalesEntries = productSalesFilteredItems.flatMap((item) => item.infos);

      const data = [
        {
          label: `ユーザー数 ${
            (isPlanService && report.hasSubscriptionPlans) || report.hasProducts ? '（※期間設定対象外）' : ''
          }`,
          type: 'users',
          isDisplay: true,
          filename: `ユーザー数_${date}.csv`,
          headers: usersHeaders,
          entries: report.users,
        },
        {
          label: `プラン別加入者数 （対象期間：${subscriptionsFilterdDates}）`,
          type: 'subscriptionCounts',
          isDisplay: isPlanService && report.hasSubscriptionPlans,
          filename: `プラン別加入数(対象期間：${subscriptionsFilterdDates.replace(/\//g, '')})_${date}.csv`,
          headers: [
            { text: 'データ取得日', value: 'csv.date' },
            { text: 'データ取得時間', value: 'csv.time' },
            { text: 'プランID', value: 'subscriptionPlanId' },
            { text: 'プラン名', value: 'subscriptionPlanName' },
            { text: '人数', value: 'count' },
          ],
          entries: subscriptionsEntries,
        },
        {
          label: `プラン売上 （対象期間：${planSalesFilterdDates}）`,
          type: 'planSales',
          isDisplay: isPlanService && report.hasSubscriptionPlans,
          filename: `プラン売上_(対象期間：${planSalesFilterdDates.replace(/\//g, '')})_${date}.csv`,
          headers: [
            { text: '売上日', value: 'csv.date' },
            { text: '売上時刻', value: 'csv.salesTime' },
            { text: 'プランID', value: 'id' },
            { text: 'プラン名', value: 'csv.name' },
            { text: '単価', value: 'price' },
            { text: '人数', value: 'count' },
            { text: '売上', value: 'totalPrice' },
            { text: '決済方法', value: 'csv.paymentType' },
          ],
          entries: planSalesEntries,
        },
        {
          label: `個別課金売上 （対象期間：${productSalesFilterdDates}）`,
          type: 'productSales',
          isDisplay: report.hasProducts,
          filename: `個別課金売上_(対象期間：${productSalesFilterdDates.replace(/\//g, '')})_${date}.csv`,
          headers: [
            { text: '売上日', value: 'csv.date' },
            { text: '売上時刻', value: 'csv.salesTime' },
            { text: '商品ID', value: 'id' },
            { text: '商品名', value: 'csv.name' },
            { text: '単価', value: 'price' },
            { text: '個数', value: 'count' },
            { text: '売上', value: 'totalPrice' },
            { text: '決済方法', value: 'csv.paymentType' },
          ],
          entries: productSalesEntries,
        },
      ];

      return data.filter((item) => item.isDisplay);
    });

    const download = (type: string) => {
      const data = csvData.value.find((item) => item.type === type);
      if (!data) return;
      downloadCsv(data.headers, data.entries, data.filename);
    };

    return {
      pageTitle: 'CSVデータダウンロード',
      myRoleSettings,
      isPlanService,
      report,
      csvFilterDates,
      csvData,
      selectedCsvType,
      download,
    };
  },
});
